import styles from './hero.module.css'
import Typewriter from 'typewriter-effect'
import Ninja from '../../../assets/image/logo.svg'
import SNS from '../../../assets/image/SNS.svg'
import Button from '../../common/button'
import Socials from '../../common/socials'
import GreenText from '../../common/greenText/greenText'

function Hero() {
  return (
    <section className={`${styles.container}`}>
      <div className={`${styles.content} max-width`}>
        <div className={styles.content__right}>
          <img
            src={Ninja}
            alt='Ninja'
            className={styles.content__right_image}
          />
        </div>
        <div className={styles.content__left}>
          <img src={SNS} alt='' className={styles.content__left_image} />
          <div className={styles.content__left_text}>
            <GreenText>A LOVE LETTER TO</GreenText>
            <span className={styles.spacer}></span>
            <Typewriter
              options={{
                strings: [
                  'degens.',
                  'broke mfers.',
                  'hustlers.',
                  'decentralization.',
                ],
                autoStart: true,
                loop: true,
              }}
            />
          </div>
          <div className={styles.content_left_buttons}>
            <Button
              buttonAs='a'
              target='_blank'
              buttonType='secondary'
              className={styles.button}
              disabled
            >
              Manifesto
            </Button>
            {/* <Button
              buttonAs="a"
              target="_blank"
              className={styles.button}
            >
              Buy $SNS
            </Button> */}
          </div>
          <Socials />
        </div>
      </div>
    </section>
  )
}

export default Hero
