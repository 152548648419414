import React, { FC } from 'react'
import styles from './socials.module.css'
import twitter from '../../../assets/image/twitter.svg'
import telegram from '../../../assets/image/telegram.svg'
import medium from '../../../assets/image/medium.svg'

const Socials: FC = () => {
  return (
    <div className={styles.social_links}>
      <a
        href='https://twitter.com/dogtag_id'
        rel='noopener noreferrer'
        target='_blank'
      >
        <img src={twitter} alt='' className={styles.social} />
      </a>
      <a
        href='https://t.me/dogtag_id'
        rel='noopener noreferrer'
        target='_blank'
      >
        <img src={telegram} alt='' className={styles.social} />
      </a>
      <a
        href='https://medium.com/dogtag-id'
        rel='noopener noreferrer'
        target='_blank'
      >
        <img src={medium} alt='' className={styles.social} />
      </a>
    </div>
  )
}

export default Socials
