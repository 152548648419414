import React, { FC } from 'react'
import styles from './footer.module.css'
import handBall from '../../../assets/image/logo.png'
import footerBg from '../../../assets/image/footer-bg.svg'
import { ReactComponent as TrippleDart } from '../../../assets/icons/3ple-dart-white.svg'
import { ReactComponent as FooterSeparator } from '../../../assets/image/footer-separator.svg'
import Button from '../button'
import Socials from '../socials'
import { DartGreen, DartWhite } from '../uitils'
const Footer: FC = () => {
  return (
    <section
      className={`${styles.container}`}
      style={{ backgroundImage: `url(${footerBg})` }}
    >
      <div className={`${styles.content} max-width`}>
        <div className={`${styles.footer_left}`}>
          <a href='#top'>
            <img src={handBall} alt='handBall' />
          </a>
        </div>
        <div className={styles.main_content}>
          <div className={styles.footer_center}>
            <h2 className={styles.title}>
              <DartWhite className={styles.trippleDart} />
              Quick Links
            </h2>
            <div className={styles.quick_links}>
              <ul>
                {/* <li>
                  <a
                    href=''
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-disabled
                  >
                    Litepaper
                  </a>
                </li> */}
                <li>
                  <a
                    href='/#'
                    target='_blank'
                    aria-disabled
                    rel='noopener noreferrer'
                  >
                    Audit (soon)
                  </a>
                </li>
                <li>
                  <a
                    href='https://medium.com/dogtag-id'
                    target='_blank'
                    rel='noopener noreferrer'
                    aria-disabled
                  >
                    Manifesto
                  </a>
                </li>
              </ul>
            </div>
            <Socials />
          </div>
          <div className='flex items-center justify-center'>
            <FooterSeparator />
          </div>
          <div className={styles.footer_right}>
            <h2 className={styles.title}>
              <DartWhite className={styles.trippleDart} />
              Get Started
            </h2>
            <div className={styles.action_buttons}>
              <Button
                href='https://app.uniswap.org/#/swap?outputCurrency=0xea4a2327e75252517535fd013b7c6706609819db'
                buttonType='primary'
                buttonAs='a'
                className={styles.button}
                target='_blank'
              >
                Buy $SNS
              </Button>
              <Button
                href='https://dashboard.dogtag.id'
                buttonType='secondary'
                buttonAs='a'
                className={styles.button}
                target='_blank'
              >
                launch app
              </Button>
            </div>
            <p className={styles.builtBy}>
              Built by{' '}
              <a href='' target='_blank' rel='noopener noreferrer'>
                bruhoshi
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Footer
