import React from 'react'
import GreenText from '../../common/greenText/greenText'
import { DartWhite, Heading } from '../../common/uitils'
import styles from './benefits.module.css'

function Benefits() {
  const benefits: { title: string; description: string }[] = [
    {
      title: 'feel like ryoshi',
      description: 'you get to hold anonymously',
    },
    {
      title: 'make friends like shytoshi',
      description: 'holder-only chat',
    },
    {
      title: 'support the decentra dream',
      description: 'rekt or rich',
    },
    {
      title: 'shibarium name service',
      description: 'fuck around and find out',
    },
  ]
  return (
    <section className={`${styles.container}`}>
      <div className={`${styles.content} max-width`}>
        <Heading>
          <span>
            <GreenText>holder</GreenText> Benefits
          </span>
        </Heading>
        <div className={styles.benefits}>
          {benefits.map(({ title, description }, i) => (
            <div key={i} className={styles.benefit}>
              <h2 className={styles.heading}>
                <DartWhite className={styles.dart} />
                <GreenText>{title}</GreenText>
              </h2>
              <p className={styles.description}>{description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Benefits
