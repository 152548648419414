import React from 'react'
import styles from './what.module.css'
import Collar from '../../../assets/image/collar.png'
import GreenText from '../../common/greenText/greenText'
import { Heading } from '../../common/uitils'

function WhatIsShonen() {
  return (
    <section className={`${styles.container}`}>
      <div className={`${styles.content} max-width`} id='about'>
        <Heading className='flex md:hidden'>
          <span>
            <GreenText>What</GreenText> is SNS?
          </span>
        </Heading>
        <div className={styles.content__left}>
          <img
            src={Collar}
            alt='Power UP'
            className={styles.content__left_image}
          />
        </div>
        <div className={styles.content__right}>
          <Heading className='md:flex hidden'>
            <span>
              <GreenText>What</GreenText> is SNS?
            </span>
          </Heading>
          <p className={styles.content__right_text}>
            SNS is a labor of love. It is a philanthropic attempt at creating
            something special and necessary for the next generation of builders
            and crypto enthusiasts.
            <br></br>
            <br></br>
          </p>
          <p>
            and remember...
            <br></br>
            <br></br>
            <i>
              “There can never be a single source of failure or any "admin" - it
              must always be donated to the{' '}
              <GreenText>shiba god of decentralization</GreenText>.”
            </i>
            <br></br>- the one and only.
          </p>
        </div>
      </div>
    </section>
  )
}

export default WhatIsShonen
